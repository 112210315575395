<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;min-width:max-content;width:calc(100% - 32px)">
                    <a-layout style="height:100%;background:#fff;padding:0 20px;">
                        <a-layout-header style="height:auto;background:#fff;padding:16px 0 ;">
                            <a-row>
                        
                                <a-col  class="textal_r">
                                    <a-button type="primary" @click="() => (modalVisible = true)">添加奖励 </a-button>
                                    <a-modal width="700px" v-model="modalVisible" title="任务类型">
                                        <div>
                                            <p style="text-align:center">
                                                <a-button type="primary" class="margin-r" @click="TaskTargetOne(1)" ghost>优惠券</a-button>
                                                <a-button type="primary" class="margin-r" @click="TaskTargetOne(2)" ghost>实物奖品</a-button>
                                                <!--<a-button type="primary" class="margin-r" @click="TaskTargetOne(3)" ghost>返利现金</a-button>-->                                                
                                            </p>
                                        </div>
                                        <div slot="footer"></div>
                                    </a-modal>
                                </a-col>
                            </a-row>
                        </a-layout-header>
                        <a-table :columns="columns" rowKey="ID" :data-source="dataList" :pagination="pagination" @change="pagerChange">
                            <span slot="action" slot-scope="text, record">

                                <a-button type="link" size="small" @click="EditRow(record.ID)">编辑</a-button>
                                <a-button type="link" size="small" @click="EditGood(record.ID)">商品详情</a-button>
                                <a-button type="link" size="small" @click="DelRow(record.ID,record.Name)">删除</a-button>
                            </span>
                        </a-table>
                    </a-layout>

                </div>
            </a-layout-content>

        </a-layout>
    </div>
</template>
<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
       name: "PrizeList",
        data() {
            return { 
                dataList:[],
                columns:[
                    //{
                    //    title: "奖励图片",
                    //    align:"center",
                    //    customRender: (text, item) => {
                            

                    //        if (item.TitleImg != null && item.TitleImg != "") {
                    //            return <div><img src={item.TitleImg} width="60" height="60" /></div>
                    //        } else {
                    //            return <div><img src="/image/tjwz.png" width="60" height="60" /></div>
                    //        }
                    //    }
                    //},
                    {
                         title: "名称",
                         dataIndex: "Name",
                    },
                    {
                        title: "奖品类型",
                        dataIndex: "PrizeType",
                        align: "center",
                        customRender: (text, item) => {
                            var displayTxt = "";
                            if (item.PrizeType == 1)
                                displayTxt = "优惠券";
                            if (item.PrizeType == 2)
                                displayTxt = "实物奖品";
                            if (item.PrizeType == 3)
                                displayTxt = "现金返现";

                            return displayTxt;
                        }
                    },
                    {
                        title: "开始时间",
                        dataIndex: "BeginTime",
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.BeginTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "结束时间",
                        dataIndex: "EndTime",
                        align: "center",
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.EndTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "数量",
                        dataIndex: "Count",
                        align: "center",
                        
                    },
                    {
                         title: "操作",
                         align: "center",
                         scopedSlots: { customRender: 'action' },
                         width: 200
                    }
                ],
                modalVisible: false,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 1 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
                pagination: {
                     total: 0,
                     defaultPageSize: 10,
                     current: 1,
                     showTotal: total => `共 ${total} 条数据`
                },
                StoreState: "0",
                StoreType: "0",
       
            };
        },
        methods:{
     
            onPaginationChange(e) {
                this.pagination.current = e
                this.loadData();
            },
            pagerChange: function(item) {
                 var self = this;
                 self.pagination.current = item.current;
                 self.loadData();
            },
            loadData() {
                var self = this;
                var op = {
                    url: "/MarketingModule/M2Activity/GetAwardPoolList",
                    data: {
                        id: this.$route.query.ActivityID,// self.ID,                 
                        pageindex: self.pagination.current
                    },
                    OnSuccess: function (res) {
                        console.log("********************");
                        console.log(res);
                        console.log("********************");
                        self.dataList = res.data.Data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            TaskTargetOne(val) {
                var url = "/MarketingModule/M2Activity/PrizeDetail?ID=-1&ActivityID=" + this.$route.query.ActivityID+"&PrizeType=" + val;          
                this.$router.push({ path: url })
            },                    
            EditRow(val) {
                
                var url = "/MarketingModule/M2Activity/PrizeDetail?ID="+val+"&ActivityID=" + this.$route.query.ActivityID;

                this.$router.push({ path: url })
            },
            EditGood(val) {
                var url = "/MarketingModule/M2Activity/PrizeGoodList?ID=" + val + "&ActivityID=" + this.$route.query.ActivityID;

                this.$router.push({ path: url })
            },
            SelectRow(e) {
                var id = 0;
                var type = 0;
                if (e != '0') {
                    id = e.StoreID;
                    type = e.StoreType;
                }
                if (type == 1)
                {
                  this.$router.push({
                    name: "Material_MomentDetail",
                    query: { id: id , type: type , rowType: 'select'}
                  });
                }
                if (type == 2)
                {
                  this.$router.push({
                    name: "Material_ExtensionDetail",
                    query: { id: id , type: type , rowType: 'select'}
                  });
                }
            },
            DelRow(id,title) {
                var self = this;
                this.$confirm({
                    title: '提示',
                    content: '是否删除' + title,
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/MarketingModule/M2Activity/DelPool",
                            data: {
                                id: id
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                self.loadData();
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            }            
        },
         mounted() {
          this.loadData();
        }
    }
</script>



